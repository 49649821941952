import { Link } from 'gatsby'
import React from 'react'

const Footer = props => (
  <footer id="footer">
    <section>
      <h2>Über uns</h2>
      <p>
        Wir sind im Bundesverband Deutscher Bestatter e.V. und gehören der Bestatterinnung Sachsen-Anhalt an.<br />
        Sie werden jederzeit freundlich empfangen und erhalten umfassenden Rat und Hilfe im Trauerfall.
      </p>
    </section>
    <section>
      <h2>Kontakt</h2>
      <dl className="alt">
        <dt>Addresse</dt>
        <dd>Lohmannstraße 135; 06366 Köthen </dd>
        <dt>Telefon</dt>
        <dd>(03496) 55 29 39</dd>
        <dt>Fax</dt>
        <dd>(03496) 21 80 23</dd>
        <dt>Email</dt>
        <dd>
          <a href="mailto:bestattungshausbartko@t-online.de">bestattungshausbartko@t-online.de</a>
        </dd>
        <dt><Link to="/impressum">Impressum</Link></dt>
      </dl>
      {/*
      <ul className="icons">
        <li>
          <a
            href="https://twitter.com/huntaroSan"
            className="icon fa-twitter alt"
          >
            <span className="label">Twitter</span>
          </a>
        </li>
        <li>
          <a href="https://codebushi.com" className="icon fa-facebook alt">
            <span className="label">Facebook</span>
          </a>
        </li>
        <li>
          <a href="https://codebushi.com" className="icon fa-instagram alt">
            <span className="label">Instagram</span>
          </a>
        </li>
        <li>
          <a
            href="https://github.com/codebushi/gatsby-starter-stellar"
            className="icon fa-github alt"
          >
            <span className="label">GitHub</span>
          </a>
        </li>
        <li>
          <a href="https://codebushi.com" className="icon fa-dribbble alt">
            <span className="label">Dribbble</span>
          </a>
        </li>
      </ul>
      */}
    </section>
    {
      /*
        <p className="copyright">
          &copy; Untitled. Design: <a href="https://html5up.net">HTML5 UP</a>.
        </p>
       */
    }
  </footer>
)

export default Footer
